<template>
  <b-card>
    <div class="text-right mb20">
      <el-button
        type="primary"
        @click="dialogVisible = true"
      >
        审核
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      fit
      border
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        v-for="(item,index) in ixitFields"
        :key="index"
        :prop="item.field"
        :show-overflow-tooltip="true"
        :label="item.label"
      >
        <template slot-scope="{row}">
          <span v-if="type !== 2">
            {{ item.field }}
          </span>
          <span v-else>
            {{ row[item.filePath] }}
            <!--            <span v-if="row[item.field]">{{ row[item.field] === 1 ? row[item.fileName] : row[item.filePath] }}</span>-->

          </span>

        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="审核"
      :visible.sync="dialogVisible"
      width="620px"
      :append-to-body="true"
      @close="dialogClose"
      @open="dialogOpen"
    >
      <el-form
        v-if="dialogVisible"
        ref="form"
        v-loading="loading"
        :model="form"
        class="assest-save-form mr10"
        label-width="100px"
      >
        <el-form-item
          label="审核结果"
          prop="auditResult"
          :rules="[ { required: true, message: '请选择审核结果', trigger: 'change' }]"
        >
          <el-select v-model="form.auditResult">
            <el-option
              v-for="(item,index) in stautusOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="理由"
        >
          <el-input
            v-model="form.auditReason"
            type="textarea"
            :autosize="{minRows: 3}"
          />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          :disabled="loading"
          @click="submitForm"
        >
          保 存
        </el-button>
      </div>
    </el-dialog>
  </b-card>

</template>
<script>
import { ixitFields } from '@/views/dataIntelligent/business/ixitField'
import { GetItemDetailsList, GetItemOtherInfo, IxitAudit } from '@/api/business/business'
import { error, success } from '@/@core/utils/utils'

export default {
  data() {
    const type = Number(this.$route.query.t)
    return {
      loading: false,
      tableData: [],
      // noTableArr: [1, 3, 8],
      noTableArr: [0, 2, 4, 9],
      type,
      ixitFields: ixitFields[type],
      id: Number(this.$route.query.id),
      dialogVisible: false,
      form: {
        id: Number(this.$route.query.id),
        auditResult: '',
        auditReason: '',
      },
      stautusOptions: [
        { label: '通过', value: 2 },
        { label: '驳回', value: 1 },
      ],
    }
  },
  created() {
    if (this.noTableArr.indexOf(this.type) === -1) {
      this.getItemDetailsList()
    } else {
      this.getItemDetails()
    }
  },
  methods: {
    getItemDetailsList() {
      GetItemDetailsList(this.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.tableData = resData.data
        }
      })
    },
    // 获取表单详情
    getItemDetails() {
      GetItemOtherInfo(this.id, this.type).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          const form = resData.data
          this.tableData = [
            {},
          ]
          this.ixitFields.forEach(ixit => {
            this.tableData[0].label = ixit.label
            this.tableData[0][ixit.field] = form[ixit.field]
            if (this.type === 2) {
              this.tableData[0][ixit.fileName] = form[ixit.fileName]
              this.tableData[0][ixit.filePath] = form[ixit.filePath]
            }
          })
        }
      })
    },
    dialogOpen() {

    },
    dialogClose() {
      this.form = {
        id: Number(this.$route.query.id),
        auditResult: '',
        auditReason: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          IxitAudit(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">

</style>
